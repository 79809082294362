//
//
// user.scss
//
// Place your own sparrow CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.fancynavbar-brand {
  padding: 0px;
  display: inline-block;
}
.fancynavbar-addon-item:focus-visible {
  outline: none;
}
.bb-line-height {
  line-height: 0.1;
}
.bb-flex-center {
  align-items: center;
}

.bb-landing-wrapper img {
  background-color: #ff5100 !important;
}
.bb-landing-wrapper .bb-showcase-item {
  border: 0.15rem solid rgba(190, 190, 190, 0.1);
}

.bb-logo {
  filter: drop-shadow(3px 6px 8px rgba(0, 0, 0, 0.52));
}
.bb-logo img {
  width: 60%;
}
.bb-line-space hr {
  width: 40%;
  background-color: #e1e1e1;
  border: 0px;
  height: 1px;
}
.showcase-content {
  position: relative;
}
.showcase-content img {
  display: block;
}
.showcase-content span {
  color: #000000;
  position: absolute;
  top: 10px;
  left: 10px;
  text-align: center;
}
.layer-1 {
  z-index: 1;
}
.layer-2 {
  z-index: 2;
}
.bb-header h1 {
  font-size: 1.75rem;
  background: linear-gradient(
    0deg,
    rgba(99, 101, 105, 0.75) 22%,
    rgba(255, 81, 0, 0.75) 99%
  );
  padding: 7px;
  color: #ffffff;
}
.bb-header p {
  background: rgba(99, 101, 105, 0.4);
  padding: 7px;
  color: #ffffff;
}
.bb-site-title {
  display: inline-block;
  background: linear-gradient(
    0deg,
    rgba(99, 101, 105, 0.75) 22%,
    rgba(255, 81, 0, 0.75) 99%
  );
  z-index: 0;
  color: #ffffff;
}
.bb-news h4 {
  display: inline-block;
  background: rgba(255, 0, 0, 0.9);
  padding: 7px;
  color: #ffffff;
}
.bb-news .btn {
  display: table;
}
.process-item-number {
  position: relative !important;
}

// breakpoint-min (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)

@include media-breakpoint-up(sm) {
  .bb-logo img {
    width: 50%;
  }
}
@include media-breakpoint-up(md) {
  .bb-logo img {
    width: 50%;
  }
  .bb-header h1 {
    display: inline-block;
    font-size: 4rem;
  }
  .cmpfullscreen .cmpboxbtn {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }
}
@include media-breakpoint-up(lg) {
  .bb-logo img {
    width: 40%;
  }
  .bb-header h1 {
    display: inline-block;
    font-size: 4rem;
  }
  .bb-news .btn {
    display: inline-block;
  }
}
@include media-breakpoint-up(xl) {
  .bb-logo img {
    width: 50%;
  }
  .bb-header h1 {
    display: inline-block;
    font-size: 5rem;
  }
  .bb-header p {
    display: inline-block;
    background: rgba(99, 101, 105, 0.6);
    padding: 7px;
    color: #ffffff;
  }
  .bb-news .btn {
    display: inline-block;
  }
}
@include media-breakpoint-down(md) {
  .bb-bg-holder {
    padding-top: 3rem;
  }
}
// sponsors
.sponsor-avatar {
  //width: 30rem;
  //height: auto;
  object-fit: cover;
  // border-radius: 50%;

  /*-----------------------------------------------
  |   Egg shaped avatar
  -----------------------------------------------*/
  &.sponsor-avatar-rect {
    //height: 12.5rem;
    //width: 11rem;
    border: ($border-width * 3) solid $white;
    box-shadow: 0 0 0 0.5rem rgba($gray-300, 0.7);
  }
}

/*-----------------------------------------------
|   Sponsor-Content
-----------------------------------------------*/
.sponsor-content {
  position: relative;
  &:before {
    position: absolute;
    left: -3rem;
    top: -0.5rem;
    line-height: 1;
    display: inline-block;
    color: $gray-300;
    font-size: map_get($font-sizes, 6);
  }
}

/*-----------------------------------------------
|   Blockquote custom
-----------------------------------------------*/
.blockquote-custom {
  color: $gray-700;
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  padding: 0.5em 10px;
}
.blockquote-custom:before {
  position: absolute;
  top: 3.2rem;
  color: #ccc;
  content: quote("\201C");
  font-size: 7em;
  line-height: 0.1em;
  margin-right: 0.25em;
}
.blockquote-custom p {
  font-style: italic;
  text-indent: 2.5em;
}
