//*-----------------------------------------------
//|   Color System
//-----------------------------------------------*/
$white: #fff !default;
$gray-100: #fafafa !default;
$gray-200: #f2f2f2 !default;
$gray-300: #e1e1e1 !default;
$gray-400: #bebebe !default;
$gray-500: #949494 !default;
$gray-600: #7f7f7f !default;
$gray-700: #666666 !default;
$gray-800: #555555 !default;
$gray-900: #404040 !default;
$gray-1000: #2b2b2b !default;
$gray-1100: #1c1c1c !default;
$black: #000 !default;

$grays: () !default;
$grays: map_merge(
    $grays,
    (
        "black": $black,
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900,
        "1000": $gray-1000,
        "1100": $gray-1100,
        "white": $white,
    )
);

//*-----------------------------------------------
//|   Solid Colors
//-----------------------------------------------*/
$blue: #045bc1 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d0021b !default;
$orange: #fd7e14 !default;
$yellow: #f37f29 !default;
$green: #7ed321 !default;
$teal: #20c997 !default;
$cyan: #00d6ff !default;

//*-----------------------------------------------
//|   Theme Colors
//-----------------------------------------------*/
$primary: $blue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-1100 !default;

$theme-colors: () !default;
$theme-colors: map_merge(
    $theme-colors,
    (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
    )
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 167 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $black !default;

//*-----------------------------------------------
//|   Social colors
//-----------------------------------------------*/
$linkedin: #0077b5 !default;
$facebook: #3b5998 !default;
$twitter: #1da1f2 !default;
$google-plus: #db4437 !default;
$github: #24292e !default;
$youtube: #ff0002 !default;

//*-----------------------------------------------
//|   Shadow
//-----------------------------------------------*/
$box-shadow-lg: 0 1rem 4rem rgba($black, 0.175) !default;

//*-----------------------------------------------
//|   Spacing
//-----------------------------------------------*/

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
    $spacers,
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.8,
        ),
        5: (
            $spacer * 3,
        ),
        6: (
            $spacer * 4,
        ),
        7: (
            $spacer * 5,
        ),
        8: (
            $spacer * 7.5,
        ),
        9: (
            $spacer * 10,
        ),
        10: (
            $spacer * 12.5,
        ),
        11: (
            $spacer * 15,
        ),
    )
);

//*-----------------------------------------------
//|   Components
//-----------------------------------------------*/
$border-radius: 0.1875rem !default;
$border-radius-lg: $border-radius !default;
$border-radius-sm: $border-radius !default;
$border-radius-soft: 0.625rem !default;
$border-radius-capsule: 3.125rem !default;

//*-----------------------------------------------
//|   Fonts Families
//-----------------------------------------------*/

$font-family-sans-serif: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-serif: "PT Serif", Times, "Times New Roman", Georgia, serif !default;
//$font-family-monospace: 'PT Mono', SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-serif !default;

//*-----------------------------------------------
//|   Fonts
//-----------------------------------------------*/

$font-sizes: () !default;
$font-sizes: (
    "-1": 0.75rem,
    0: 1rem,
    1: 1.333rem,
    2: 1.777rem,
    3: 2.369rem,
    4: 3.157rem,
    5: 4.199rem,
    6: 5.584rem,
    7: 7.427rem,
    8: 9.878rem,
);

$font-size-lg: map_get($font-sizes, 1) !default;
$font-size-sm: map_get($font-sizes, "-1") !default;

$font-weight-thin: 100 !default;
$font-weight-extra-light: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-extra-bold: 800 !default;
$font-weight-black: 900 !default;

$font-size-base: 1rem !default;
$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.45 !default;

$h1-font-size: $font-size-base * 3.157 !default;
$h2-font-size: $font-size-base * 2.369 !default;
$h3-font-size: $font-size-base * 1.777 !default;
$h4-font-size: $font-size-base * 1.333 !default;
$h5-font-size: $font-size-base * 1 !default;
$h6-font-size: $font-size-base * 0.75 !default;

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family: $font-family-sans-serif !default;
$headings-font-weight: $font-weight-black !default;
$headings-line-height: 1.2 !default;
$headings-color: $black !default;

$display1-size: map_get($font-sizes, 8) !default;
$display2-size: map_get($font-sizes, 7) !default;
$display3-size: map_get($font-sizes, 6) !default;
$display4-size: map_get($font-sizes, 5) !default;

$display1-weight: $font-weight-black !default;
$display2-weight: $font-weight-black !default;
$display3-weight: $font-weight-black !default;
$display4-weight: $font-weight-black !default;
$display-line-height: 1 !default;

$lead-font-size: map_get($font-sizes, 1) !default;
$lead-font-weight: $font-weight-normal !default;

$small-font-size: 75% !default;

$text-muted: $gray-500 !default;

$blockquote-small-color: $gray-500 !default;
$blockquote-font-size: map_get($font-sizes, 1) !default;

//*-----------------------------------------------
//|   Cards
//-----------------------------------------------*/
$card-spacer-y: map_get($spacers, 3) !default;
$card-spacer-x: map_get($spacers, 5) !default;

//*-----------------------------------------------
//|   Body
//-----------------------------------------------*/

$body-bg: $white !default;
$body-color: $gray-700 !default;

//*-----------------------------------------------
//|   Buttons and Forms
//-----------------------------------------------*/

$input-btn-padding-y: 0.8rem !default;
$input-btn-padding-x: 2.5rem !default;

$input-btn-padding-y-sm: 0.4rem !default;
$input-btn-padding-x-sm: 1.2rem !default;

$input-btn-padding-y-lg: 1.2rem !default;
$input-btn-padding-x-lg: 5rem !default;

//*-----------------------------------------------
//|   Buttons
//-----------------------------------------------*/
$btn-font-weight: $font-weight-bold !default;

//*-----------------------------------------------
//|   Badge
//-----------------------------------------------*/
$badge-font-weight: $font-weight-black !default;

//*-----------------------------------------------
//|   Forms
//-----------------------------------------------*/
$input-disabled-bg: $gray-200 !default;
$input-group-addon-bg: $gray-200 !default;
$input-border-color: $gray-300 !default;
$input-color: $gray-900 !default;
$input-padding-x: $input-btn-padding-y !default;
$input-padding-x-sm: $input-btn-padding-y-sm !default;
$input-padding-x-lg: $input-btn-padding-y-lg !default;

//*-----------------------------------------------
//|   Pagination
//-----------------------------------------------*/
$pagination-padding-y: 0.5rem !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $black !default;
$pagination-border-color: $gray-300 !default;

$pagination-hover-color: $white !default;
$pagination-hover-bg: $black !default;
$pagination-hover-border-color: $black !default;

$pagination-active-color: $white !default;
$pagination-active-bg: $black !default;
$pagination-active-border-color: $black !default;

//*-----------------------------------------------
//|   Tables
//-----------------------------------------------*/
$table-border-color: $gray-300 !default;
$table-head-bg: $gray-200 !default;
$table-head-color: $dark !default;
$table-dark-bg: $gray-1000 !default;
$table-dark-border-color: lighten($gray-1000, 7.5%) !default;
$table-striped-order: even !default;

//*-----------------------------------------------
//|   Navigation
//-----------------------------------------------*/
$navbar-dark-color: rgba($white, 0.7) !default;
$navbar-dark-hover-color: rgba($white, 0.8) !default;
$navbar-font-size: $font-size-base * 0.8 !default;

$navbar-light-color: rgba($black, 0.55) !default;
$navbar-light-hover-color: rgba($black, 0.9) !default;
$navbar-light-active-color: $black !default;
$navbar-light-disabled-color: rgba($black, 0.25) !default;

$navbar-dark-color: rgba($white, 0.55) !default;
$navbar-dark-hover-color: rgba($white, 0.9) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;

$navbar-padding-y: map_get($spacers, 2) !default;
$navbar-padding-x: $spacer !default;

//*-----------------------------------------------
//|   Dropdowns | Dropdown menu container and contents.
//-----------------------------------------------*/
$dropdown-link-color: $gray-700 !default;
$dropdown-link-hover-color: $gray-900 !default;
$dropdown-link-hover-bg: $gray-200 !default;
$dropdown-divider-bg: $gray-300 !default;

$dropdown-font-size: $navbar-font-size !default;
$dropdown-item-padding-y: 0.4rem !default;
$dropdown-item-padding-x: 1.2rem !default;

//*-----------------------------------------------
//|   Breadcrumbs
//-----------------------------------------------*/
$breadcrumb-padding-x: 0 !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-bg: "transparent";
$breadcrumb-divider: quote("\00BB") !default;

//*-----------------------------------------------
//|   Fancynav
//-----------------------------------------------*/
$fancynav-width: 4.375rem !default;
$fancynav-breakpoint-up: lg !default;
$fancynav-breakpoint-down: md !default;
$fancynavHeight: 3rem !default;
$fancynav-collapse-bg: $black !default;
$fancynav-link-color: $white !default;

//*-----------------------------------------------
//|   modal
//-----------------------------------------------*/

$modal-backdrop-bg: rgb(28, 28, 28) !default;
$modal-backdrop-opacity: 0.9 !default;
$modal-fade-in-transform: scale(1) !default;
$modal-xs: 12.5rem !default;
$zindex-modal-backdrop: 10040 !default;
$zindex-modal: 10050 !default;
