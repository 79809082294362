//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

//*-----------------------------------------------
//|   Solid Colors
//-----------------------------------------------*/

//*-----------------------------------------------
//|   Theme Colors
//-----------------------------------------------*/
$primary: #ff5100;
$bb-primary: #ff5100;

// Links
//
// Style anchor elements.

/* $link-color: #ff5100;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none; */

//*-----------------------------------------------
//|   Spacing
//-----------------------------------------------*/

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
    $spacers,
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.8,
        ),
        5: (
            $spacer * 3,
        ),
        6: (
            $spacer * 4,
        ),
        7: (
            $spacer * 5,
        ),
        8: (
            $spacer * 7.5,
        ),
        9: (
            $spacer * 10,
        ),
        10: (
            $spacer * 12.5,
        ),
        11: (
            $spacer * 15,
        ),
        12: (
            $spacer * 16,
        ),
        13: (
            $spacer * 17,
        ),
        14: (
            $spacer * 18,
        ),
        15: (
            $spacer * 19,
        ),
        16: (
            $spacer * 20,
        ),
    )
);

/*-----------------------------------------------
|   Letter Spacing
-----------------------------------------------*/
.ls-1 {
    letter-spacing: 0.25em;
}
.ls-2 {
    letter-spacing: 0.5em;
}
.ls-3 {
    letter-spacing: 0.75em;
}
