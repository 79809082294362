/*-----------------------------------------------
|   BB-LandingPage-Showcase
-----------------------------------------------*/
.bb-landing-wrapper {
    transform: translateY(-50%) rotate(45deg);
    transform-origin: center;
    position: absolute;
    top: 50%;
    left: 35%;
    width: 30rem;
    height: 30rem;
    @include media-breakpoint-up(sm) {
        left: 50%;
        width: 45rem;
        height: 45rem;
    }
    @include media-breakpoint-up(md) {
        left: 40%;
        width: 80rem;
        height: 80rem;
    }
    @include media-breakpoint-up(lg) {
        left: 40%;
        width: 80rem;
        height: 80rem;
    }
    @include media-breakpoint-up(xl) {
        left: 40%;
        width: 90em;
        height: 90rem;
    }

    .bb-showcase-item {
        width: 100% !important;
        border-radius: 0.5rem;
        border: 0.25rem solid rgba($gray-400, 0.1);
        box-shadow: 0.7rem 0.1rem 1.25rem 0 rgba($black, 0.4);
        width: 6.25rem;
        transition: transform 2s cubic-bezier(0.77, 0, 0.18, 1);
    }

    $left: 11%;
    $top: 11%;

    [class*="bb-showcase-r"] {
        width: 10.5%;
        position: absolute;
        color: #ffffff;
    }
    .bb-showcase-r0-0 {
        left: 0%;
        top: 0%;
    }
    .bb-showcase-r0-1 {
        left: ($left * 1);
        top: 0%;
    }
    .bb-showcase-r0-2 {
        left: ($left * 2);
        top: 0%;
    }
    .bb-showcase-r0-3 {
        left: ($left * 3);
        top: 0%;
    }
    .bb-showcase-r0-4 {
        left: ($left * 4);
        top: 0%;
    }
    .bb-showcase-r0-5 {
        left: ($left * 5);
        top: 0%;
    }
    .bb-showcase-r0-6 {
        left: ($left * 6);
        top: 0%;
    }
    .bb-showcase-r0-7 {
        left: ($left * 7);
        top: 0%;
    }
    .bb-showcase-r0-8 {
        left: ($left * 8);
        top: 0%;
    }
    .bb-showcase-r0-9 {
        left: ($left * 9);
        top: 0%;
    }
    .bb-showcase-r0-10 {
        left: ($left * 10);
        top: 0%;
    }
    .bb-showcase-r0-11 {
        left: ($left * 11);
        top: 0%;
    }
    .bb-showcase-r0-12 {
        left: ($left * 12);
        top: 0%;
    }
    .bb-showcase-r0-13 {
        left: ($left * 13);
        top: 0%;
    }
    .bb-showcase-r0-14 {
        left: ($left * 14);
        top: 0%;
    }
    .bb-showcase-r1-0 {
        left: 0%;
        top: ($top * 1);
    }
    .bb-showcase-r1-1 {
        left: ($left * 1);
        top: ($top * 1);
    }
    .bb-showcase-r1-2 {
        left: ($left * 2);
        top: ($top * 1);
    }
    .bb-showcase-r1-3 {
        left: ($left * 3);
        top: ($top * 1);
    }
    .bb-showcase-r1-4 {
        left: ($left * 4);
        top: ($top * 1);
    }
    .bb-showcase-r1-5 {
        left: ($left * 5);
        top: ($top * 1);
    }
    .bb-showcase-r1-6 {
        left: ($left * 6);
        top: ($top * 1);
    }
    .bb-showcase-r1-7 {
        left: ($left * 7);
        top: ($top * 1);
    }
    .bb-showcase-r1-8 {
        left: ($left * 8);
        top: ($top * 1);
    }
    .bb-showcase-r1-9 {
        left: ($left * 9);
        top: ($top * 1);
    }
    .bb-showcase-r1-10 {
        left: ($left * 10);
        top: ($top * 1);
    }
    .bb-showcase-r1-11 {
        left: ($left * 11);
        top: ($top * 1);
    }
    .bb-showcase-r1-12 {
        left: ($left * 12);
        top: ($top * 1);
    }
    .bb-showcase-r1-13 {
        left: ($left * 13);
        top: ($top * 1);
    }
    .bb-showcase-r1-14 {
        left: ($left * 14);
        top: ($top * 1);
    }
    .bb-showcase-r2-0 {
        left: 0%;
        top: ($top * 2);
    }
    .bb-showcase-r2-1 {
        left: ($left * 1);
        top: ($top * 2);
    }
    .bb-showcase-r2-2 {
        left: ($left * 2);
        top: ($top * 2);
    }
    .bb-showcase-r2-3 {
        left: ($left * 3);
        top: ($top * 2);
    }
    .bb-showcase-r2-4 {
        left: ($left * 4);
        top: ($top * 2);
    }
    .bb-showcase-r2-5 {
        left: ($left * 5);
        top: ($top * 2);
    }
    .bb-showcase-r2-6 {
        left: ($left * 6);
        top: ($top * 2);
    }
    .bb-showcase-r2-7 {
        left: ($left * 7);
        top: ($top * 2);
    }
    .bb-showcase-r2-8 {
        left: ($left * 8);
        top: ($top * 2);
    }
    .bb-showcase-r2-9 {
        left: ($left * 9);
        top: ($top * 2);
    }
    .bb-showcase-r2-10 {
        left: ($left * 10);
        top: ($top * 2);
    }
    .bb-showcase-r2-11 {
        left: ($left * 11);
        top: ($top * 2);
    }
    .bb-showcase-r2-12 {
        left: ($left * 12);
        top: ($top * 2);
    }
    .bb-showcase-r2-13 {
        left: ($left * 13);
        top: ($top * 2);
    }
    .bb-showcase-r2-14 {
        left: ($left * 14);
        top: ($top * 2);
    }
    .bb-showcase-r3-0 {
        left: 0%;
        top: ($top * 3);
    }
    .bb-showcase-r3-1 {
        left: ($left * 1);
        top: ($top * 3);
    }
    .bb-showcase-r3-2 {
        left: ($left * 2);
        top: ($top * 3);
    }
    .bb-showcase-r3-3 {
        left: ($left * 3);
        top: ($top * 3);
    }
    .bb-showcase-r3-4 {
        left: ($left * 4);
        top: ($top * 3);
    }
    .bb-showcase-r3-5 {
        left: ($left * 5);
        top: ($top * 3);
    }
    .bb-showcase-r3-6 {
        left: ($left * 6);
        top: ($top * 3);
    }
    .bb-showcase-r3-7 {
        left: ($left * 7);
        top: ($top * 3);
    }
    .bb-showcase-r3-8 {
        left: ($left * 8);
        top: ($top * 3);
    }
    .bb-showcase-r3-9 {
        left: ($left * 9);
        top: ($top * 3);
    }
    .bb-showcase-r3-10 {
        left: ($left * 10);
        top: ($top * 3);
    }
    .bb-showcase-r3-11 {
        left: ($left * 11);
        top: ($top * 3);
    }
    .bb-showcase-r3-12 {
        left: ($left * 12);
        top: ($top * 3);
    }
    .bb-showcase-r3-13 {
        left: ($left * 13);
        top: ($top * 3);
    }
    .bb-showcase-r3-14 {
        left: ($left * 14);
        top: ($top * 3);
    }
    .bb-showcase-r4-0 {
        left: 0%;
        top: ($top * 4);
    }
    .bb-showcase-r4-1 {
        left: ($left * 1);
        top: ($top * 4);
    }
    .bb-showcase-r4-2 {
        left: ($left * 2);
        top: ($top * 4);
    }
    .bb-showcase-r4-3 {
        left: ($left * 3);
        top: ($top * 4);
    }
    .bb-showcase-r4-4 {
        left: ($left * 4);
        top: ($top * 4);
    }
    .bb-showcase-r4-5 {
        left: ($left * 5);
        top: ($top * 4);
    }
    .bb-showcase-r4-6 {
        left: ($left * 6);
        top: ($top * 4);
    }
    .bb-showcase-r4-7 {
        left: ($left * 7);
        top: ($top * 4);
    }
    .bb-showcase-r4-8 {
        left: ($left * 8);
        top: ($top * 4);
    }
    .bb-showcase-r4-9 {
        left: ($left * 9);
        top: ($top * 4);
    }
    .bb-showcase-r4-10 {
        left: ($left * 10);
        top: ($top * 4);
    }
    .bb-showcase-r4-11 {
        left: ($left * 11);
        top: ($top * 4);
    }
    .bb-showcase-r4-12 {
        left: ($left * 12);
        top: ($top * 4);
    }
    .bb-showcase-r4-13 {
        left: ($left * 13);
        top: ($top * 4);
    }
    .bb-showcase-r4-14 {
        left: ($left * 14);
        top: ($top * 4);
    }
    .bb-showcase-r5-0 {
        left: 0%;
        top: ($top * 5);
    }
    .bb-showcase-r5-1 {
        left: ($left * 1);
        top: ($top * 5);
    }
    .bb-showcase-r5-2 {
        left: ($left * 2);
        top: ($top * 5);
    }
    .bb-showcase-r5-3 {
        left: ($left * 3);
        top: ($top * 5);
    }
    .bb-showcase-r5-4 {
        left: ($left * 4);
        top: ($top * 5);
    }
    .bb-showcase-r5-5 {
        left: ($left * 5);
        top: ($top * 5);
    }
    .bb-showcase-r5-6 {
        left: ($left * 6);
        top: ($top * 5);
    }
    .bb-showcase-r5-7 {
        left: ($left * 7);
        top: ($top * 5);
    }
    .bb-showcase-r5-8 {
        left: ($left * 8);
        top: ($top * 5);
    }
    .bb-showcase-r5-9 {
        left: ($left * 9);
        top: ($top * 5);
    }
    .bb-showcase-r5-10 {
        left: ($left * 10);
        top: ($top * 5);
    }
    .bb-showcase-r5-11 {
        left: ($left * 11);
        top: ($top * 5);
    }
    .bb-showcase-r5-12 {
        left: ($left * 12);
        top: ($top * 5);
    }
    .bb-showcase-r5-13 {
        left: ($left * 13);
        top: ($top * 5);
    }
    .bb-showcase-r5-14 {
        left: ($left * 14);
        top: ($top * 5);
    }
    .bb-showcase-r6-0 {
        left: 0%;
        top: ($top * 6);
    }
    .bb-showcase-r6-1 {
        left: ($left * 1);
        top: ($top * 6);
    }
    .bb-showcase-r6-2 {
        left: ($left * 2);
        top: ($top * 6);
    }
    .bb-showcase-r6-3 {
        left: ($left * 3);
        top: ($top * 6);
    }
    .bb-showcase-r6-4 {
        left: ($left * 4);
        top: ($top * 6);
    }
    .bb-showcase-r6-5 {
        left: ($left * 5);
        top: ($top * 6);
    }
    .bb-showcase-r6-6 {
        left: ($left * 6);
        top: ($top * 6);
    }
    .bb-showcase-r6-7 {
        left: ($left * 7);
        top: ($top * 6);
    }
    .bb-showcase-r6-8 {
        left: ($left * 8);
        top: ($top * 6);
    }
    .bb-showcase-r6-9 {
        left: ($left * 9);
        top: ($top * 6);
    }
    .bb-showcase-r6-10 {
        left: ($left * 10);
        top: ($top * 6);
    }
    .bb-showcase-r6-11 {
        left: ($left * 11);
        top: ($top * 6);
    }
    .bb-showcase-r6-12 {
        left: ($left * 12);
        top: ($top * 6);
    }
    .bb-showcase-r6-13 {
        left: ($left * 13);
        top: ($top * 6);
    }
    .bb-showcase-r6-14 {
        left: ($left * 14);
        top: ($top * 6);
    }
    .bb-showcase-r7-0 {
        left: 0%;
        top: ($top * 7);
    }
    .bb-showcase-r7-1 {
        left: ($left * 1);
        top: ($top * 7);
    }
    .bb-showcase-r7-2 {
        left: ($left * 2);
        top: ($top * 7);
    }
    .bb-showcase-r7-3 {
        left: ($left * 3);
        top: ($top * 7);
    }
    .bb-showcase-r7-4 {
        left: ($left * 4);
        top: ($top * 7);
    }
    .bb-showcase-r7-5 {
        left: ($left * 5);
        top: ($top * 7);
    }
    .bb-showcase-r7-6 {
        left: ($left * 6);
        top: ($top * 7);
    }
    .bb-showcase-r7-7 {
        left: ($left * 7);
        top: ($top * 7);
    }
    .bb-showcase-r7-8 {
        left: ($left * 8);
        top: ($top * 7);
    }
    .bb-showcase-r7-9 {
        left: ($left * 9);
        top: ($top * 7);
    }
    .bb-showcase-r7-10 {
        left: ($left * 10);
        top: ($top * 7);
    }
    .bb-showcase-r7-11 {
        left: ($left * 11);
        top: ($top * 7);
    }
    .bb-showcase-r7-12 {
        left: ($left * 12);
        top: ($top * 7);
    }
    .bb-showcase-r7-13 {
        left: ($left * 13);
        top: ($top * 7);
    }
    .bb-showcase-r7-14 {
        left: ($left * 14);
        top: ($top * 7);
    }
    .bb-showcase-r8-0 {
        left: 0%;
        top: ($top * 8);
    }
    .bb-showcase-r8-1 {
        left: ($left * 1);
        top: ($top * 8);
    }
    .bb-showcase-r8-2 {
        left: ($left * 2);
        top: ($top * 8);
    }
    .bb-showcase-r8-3 {
        left: ($left * 3);
        top: ($top * 8);
    }
    .bb-showcase-r8-4 {
        left: ($left * 4);
        top: ($top * 8);
    }
    .bb-showcase-r8-5 {
        left: ($left * 5);
        top: ($top * 8);
    }
    .bb-showcase-r8-6 {
        left: ($left * 6);
        top: ($top * 8);
    }
    .bb-showcase-r8-7 {
        left: ($left * 7);
        top: ($top * 8);
    }
    .bb-showcase-r8-8 {
        left: ($left * 8);
        top: ($top * 8);
    }
    .bb-showcase-r8-9 {
        left: ($left * 9);
        top: ($top * 8);
    }
    .bb-showcase-r8-10 {
        left: ($left * 10);
        top: ($top * 8);
    }
    .bb-showcase-r8-11 {
        left: ($left * 11);
        top: ($top * 8);
    }
    .bb-showcase-r8-12 {
        left: ($left * 12);
        top: ($top * 8);
    }
    .bb-showcase-r8-13 {
        left: ($left * 13);
        top: ($top * 8);
    }
    .bb-showcase-r8-14 {
        left: ($left * 14);
        top: ($top * 8);
    }
    .bb-showcase-r9-0 {
        left: 0%;
        top: ($top * 9);
    }
    .bb-showcase-r9-1 {
        left: ($left * 1);
        top: ($top * 9);
    }
    .bb-showcase-r9-2 {
        left: ($left * 2);
        top: ($top * 9);
    }
    .bb-showcase-r9-3 {
        left: ($left * 3);
        top: ($top * 9);
    }
    .bb-showcase-r9-4 {
        left: ($left * 4);
        top: ($top * 9);
    }
    .bb-showcase-r9-5 {
        left: ($left * 5);
        top: ($top * 9);
    }
    .bb-showcase-r9-6 {
        left: ($left * 6);
        top: ($top * 9);
    }
    .bb-showcase-r9-7 {
        left: ($left * 7);
        top: ($top * 9);
    }
    .bb-showcase-r9-8 {
        left: ($left * 8);
        top: ($top * 9);
    }
    .bb-showcase-r9-9 {
        left: ($left * 9);
        top: ($top * 9);
    }
    .bb-showcase-r9-10 {
        left: ($left * 10);
        top: ($top * 9);
    }
    .bb-showcase-r9-11 {
        left: ($left * 11);
        top: ($top * 9);
    }
    .bb-showcase-r9-12 {
        left: ($left * 12);
        top: ($top * 9);
    }
    .bb-showcase-r9-13 {
        left: ($left * 13);
        top: ($top * 9);
    }
    .bb-showcase-r9-14 {
        left: ($left * 14);
        top: ($top * 9);
    }
    .bb-showcase-r10-0 {
        left: 0%;
        top: ($top * 10);
    }
    .bb-showcase-r10-1 {
        left: ($left * 1);
        top: ($top * 10);
    }
    .bb-showcase-r10-2 {
        left: ($left * 2);
        top: ($top * 10);
    }
    .bb-showcase-r10-3 {
        left: ($left * 3);
        top: ($top * 10);
    }
    .bb-showcase-r10-4 {
        left: ($left * 4);
        top: ($top * 10);
    }
    .bb-showcase-r10-5 {
        left: ($left * 5);
        top: ($top * 10);
    }
    .bb-showcase-r10-6 {
        left: ($left * 6);
        top: ($top * 10);
    }
    .bb-showcase-r10-7 {
        left: ($left * 7);
        top: ($top * 10);
    }
    .bb-showcase-r10-8 {
        left: ($left * 8);
        top: ($top * 10);
    }
    .bb-showcase-r10-9 {
        left: ($left * 9);
        top: ($top * 10);
    }
    .bb-showcase-r10-10 {
        left: ($left * 10);
        top: ($top * 10);
    }
    .bb-showcase-r10-11 {
        left: ($left * 11);
        top: ($top * 10);
    }
    .bb-showcase-r10-12 {
        left: ($left * 12);
        top: ($top * 10);
    }
    .bb-showcase-r10-13 {
        left: ($left * 13);
        top: ($top * 10);
    }
    .bb-showcase-r10-14 {
        left: ($left * 14);
        top: ($top * 10);
    }
    .bb-showcase-r11-0 {
        left: 0%;
        top: ($top * 11);
    }
    .bb-showcase-r11-1 {
        left: ($left * 1);
        top: ($top * 11);
    }
    .bb-showcase-r11-2 {
        left: ($left * 2);
        top: ($top * 11);
    }
    .bb-showcase-r11-3 {
        left: ($left * 3);
        top: ($top * 11);
    }
    .bb-showcase-r11-4 {
        left: ($left * 4);
        top: ($top * 11);
    }
    .bb-showcase-r11-5 {
        left: ($left * 5);
        top: ($top * 11);
    }
    .bb-showcase-r11-6 {
        left: ($left * 6);
        top: ($top * 11);
    }
    .bb-showcase-r11-7 {
        left: ($left * 7);
        top: ($top * 11);
    }
    .bb-showcase-r11-8 {
        left: ($left * 8);
        top: ($top * 11);
    }
    .bb-showcase-r11-9 {
        left: ($left * 9);
        top: ($top * 11);
    }
    .bb-showcase-r11-10 {
        left: ($left * 10);
        top: ($top * 11);
    }
    .bb-showcase-r11-11 {
        left: ($left * 11);
        top: ($top * 11);
    }
    .bb-showcase-r11-12 {
        left: ($left * 12);
        top: ($top * 11);
    }
    .bb-showcase-r11-13 {
        left: ($left * 13);
        top: ($top * 11);
    }
    .bb-showcase-r11-14 {
        left: ($left * 14);
        top: ($top * 11);
    }
    .bb-showcase-r12-0 {
        left: 0%;
        top: ($top * 12);
    }
    .bb-showcase-r12-1 {
        left: ($left * 1);
        top: ($top * 12);
    }
    .bb-showcase-r12-2 {
        left: ($left * 2);
        top: ($top * 12);
    }
    .bb-showcase-r12-3 {
        left: ($left * 3);
        top: ($top * 12);
    }
    .bb-showcase-r12-4 {
        left: ($left * 4);
        top: ($top * 12);
    }
    .bb-showcase-r12-5 {
        left: ($left * 5);
        top: ($top * 12);
    }
    .bb-showcase-r12-6 {
        left: ($left * 6);
        top: ($top * 12);
    }
    .bb-showcase-r12-7 {
        left: ($left * 7);
        top: ($top * 12);
    }
    .bb-showcase-r12-8 {
        left: ($left * 8);
        top: ($top * 12);
    }
    .bb-showcase-r12-9 {
        left: ($left * 9);
        top: ($top * 12);
    }
    .bb-showcase-r12-10 {
        left: ($left * 10);
        top: ($top * 12);
    }
    .bb-showcase-r12-11 {
        left: ($left * 11);
        top: ($top * 12);
    }
    .bb-showcase-r12-12 {
        left: ($left * 12);
        top: ($top * 12);
    }
    .bb-showcase-r12-13 {
        left: ($left * 13);
        top: ($top * 12);
    }
    .bb-showcase-r12-14 {
        left: ($left * 14);
        top: ($top * 12);
    }
    .bb-showcase-r13-0 {
        left: 0%;
        top: ($top * 13);
    }
    .bb-showcase-r13-1 {
        left: ($left * 1);
        top: ($top * 13);
    }
    .bb-showcase-r13-2 {
        left: ($left * 2);
        top: ($top * 13);
    }
    .bb-showcase-r13-3 {
        left: ($left * 3);
        top: ($top * 13);
    }
    .bb-showcase-r13-4 {
        left: ($left * 4);
        top: ($top * 13);
    }
    .bb-showcase-r13-5 {
        left: ($left * 5);
        top: ($top * 13);
    }
    .bb-showcase-r13-6 {
        left: ($left * 6);
        top: ($top * 13);
    }
    .bb-showcase-r13-7 {
        left: ($left * 7);
        top: ($top * 13);
    }
    .bb-showcase-r13-8 {
        left: ($left * 8);
        top: ($top * 13);
    }
    .bb-showcase-r13-9 {
        left: ($left * 9);
        top: ($top * 13);
    }
    .bb-showcase-r13-10 {
        left: ($left * 10);
        top: ($top * 13);
    }
    .bb-showcase-r13-11 {
        left: ($left * 11);
        top: ($top * 13);
    }
    .bb-showcase-r13-12 {
        left: ($left * 12);
        top: ($top * 13);
    }
    .bb-showcase-r13-13 {
        left: ($left * 13);
        top: ($top * 13);
    }
    .bb-showcase-r13-14 {
        left: ($left * 14);
        top: ($top * 13);
    }
    .bb-showcase-r14-0 {
        left: 0%;
        top: ($top * 14);
    }
    .bb-showcase-r14-1 {
        left: ($left * 1);
        top: ($top * 14);
    }
    .bb-showcase-r14-2 {
        left: ($left * 2);
        top: ($top * 14);
    }
    .bb-showcase-r14-3 {
        left: ($left * 3);
        top: ($top * 14);
    }
    .bb-showcase-r14-4 {
        left: ($left * 4);
        top: ($top * 14);
    }
    .bb-showcase-r14-5 {
        left: ($left * 5);
        top: ($top * 14);
    }
    .bb-showcase-r14-6 {
        left: ($left * 6);
        top: ($top * 14);
    }
    .bb-showcase-r14-7 {
        left: ($left * 7);
        top: ($top * 14);
    }
    .bb-showcase-r14-8 {
        left: ($left * 8);
        top: ($top * 14);
    }
    .bb-showcase-r14-9 {
        left: ($left * 9);
        top: ($top * 14);
    }
    .bb-showcase-r14-10 {
        left: ($left * 10);
        top: ($top * 14);
    }
    .bb-showcase-r14-11 {
        left: ($left * 11);
        top: ($top * 14);
    }
    .bb-showcase-r14-12 {
        left: ($left * 12);
        top: ($top * 14);
    }
    .bb-showcase-r14-13 {
        left: ($left * 13);
        top: ($top * 14);
    }
    .bb-showcase-r14-14 {
        left: ($left * 14);
        top: ($top * 14);
    }

    /*  @include media-breakpoint-up(lg) {
        $left: 11%;
        $top: 11%;

        [class*="bb-showcase-r"] {
            width: 10%;
            position: absolute;
            color: #ffffff;
        }
        .bb-showcase-r0-0 {
            left: 0%;
            top: 0%;
        }
        .bb-showcase-r0-1 {
            left: ($left * 1);
            top: 0%;
        }
        .bb-showcase-r0-2 {
            left: ($left * 2);
            top: 0%;
        }
        .bb-showcase-r0-3 {
            left: ($left * 3);
            top: 0%;
        }
        .bb-showcase-r0-4 {
            left: ($left * 4);
            top: 0%;
        }
        .bb-showcase-r0-5 {
            left: ($left * 5);
            top: 0%;
        }
        .bb-showcase-r0-6 {
            left: ($left * 6);
            top: 0%;
        }
        .bb-showcase-r0-7 {
            left: ($left * 7);
            top: 0%;
        }
        .bb-showcase-r0-8 {
            left: ($left * 8);
            top: 0%;
        }
        .bb-showcase-r0-9 {
            left: ($left * 9);
            top: 0%;
        }
        .bb-showcase-r0-10 {
            left: ($left * 10);
            top: 0%;
        }
        .bb-showcase-r0-11 {
            left: ($left * 11);
            top: 0%;
        }
        .bb-showcase-r0-12 {
            left: ($left * 12);
            top: 0%;
        }
        .bb-showcase-r0-13 {
            left: ($left * 13);
            top: 0%;
        }
        .bb-showcase-r0-14 {
            left: ($left * 14);
            top: 0%;
        }
        .bb-showcase-r1-0 {
            left: 0%;
            top: ($top * 1);
        }
        .bb-showcase-r1-1 {
            left: ($left * 1);
            top: ($top * 1);
        }
        .bb-showcase-r1-2 {
            left: ($left * 2);
            top: ($top * 1);
        }
        .bb-showcase-r1-3 {
            left: ($left * 3);
            top: ($top * 1);
        }
        .bb-showcase-r1-4 {
            left: ($left * 4);
            top: ($top * 1);
        }
        .bb-showcase-r1-5 {
            left: ($left * 5);
            top: ($top * 1);
        }
        .bb-showcase-r1-6 {
            left: ($left * 6);
            top: ($top * 1);
        }
        .bb-showcase-r1-7 {
            left: ($left * 7);
            top: ($top * 1);
        }
        .bb-showcase-r1-8 {
            left: ($left * 8);
            top: ($top * 1);
        }
        .bb-showcase-r1-9 {
            left: ($left * 9);
            top: ($top * 1);
        }
        .bb-showcase-r1-10 {
            left: ($left * 10);
            top: ($top * 1);
        }
        .bb-showcase-r1-11 {
            left: ($left * 11);
            top: ($top * 1);
        }
        .bb-showcase-r1-12 {
            left: ($left * 12);
            top: ($top * 1);
        }
        .bb-showcase-r1-13 {
            left: ($left * 13);
            top: ($top * 1);
        }
        .bb-showcase-r1-14 {
            left: ($left * 14);
            top: ($top * 1);
        }
        .bb-showcase-r2-0 {
            left: 0%;
            top: ($top * 2);
        }
        .bb-showcase-r2-1 {
            left: ($left * 1);
            top: ($top * 2);
        }
        .bb-showcase-r2-2 {
            left: ($left * 2);
            top: ($top * 2);
        }
        .bb-showcase-r2-3 {
            left: ($left * 3);
            top: ($top * 2);
        }
        .bb-showcase-r2-4 {
            left: ($left * 4);
            top: ($top * 2);
        }
        .bb-showcase-r2-5 {
            left: ($left * 5);
            top: ($top * 2);
        }
        .bb-showcase-r2-6 {
            left: ($left * 6);
            top: ($top * 2);
        }
        .bb-showcase-r2-7 {
            left: ($left * 7);
            top: ($top * 2);
        }
        .bb-showcase-r2-8 {
            left: ($left * 8);
            top: ($top * 2);
        }
        .bb-showcase-r2-9 {
            left: ($left * 9);
            top: ($top * 2);
        }
        .bb-showcase-r2-10 {
            left: ($left * 10);
            top: ($top * 2);
        }
        .bb-showcase-r2-11 {
            left: ($left * 11);
            top: ($top * 2);
        }
        .bb-showcase-r2-12 {
            left: ($left * 12);
            top: ($top * 2);
        }
        .bb-showcase-r2-13 {
            left: ($left * 13);
            top: ($top * 2);
        }
        .bb-showcase-r2-14 {
            left: ($left * 14);
            top: ($top * 2);
        }
        .bb-showcase-r3-0 {
            left: 0%;
            top: ($top * 3);
        }
        .bb-showcase-r3-1 {
            left: ($left * 1);
            top: ($top * 3);
        }
        .bb-showcase-r3-2 {
            left: ($left * 2);
            top: ($top * 3);
        }
        .bb-showcase-r3-3 {
            left: ($left * 3);
            top: ($top * 3);
        }
        .bb-showcase-r3-4 {
            left: ($left * 4);
            top: ($top * 3);
        }
        .bb-showcase-r3-5 {
            left: ($left * 5);
            top: ($top * 3);
        }
        .bb-showcase-r3-6 {
            left: ($left * 6);
            top: ($top * 3);
        }
        .bb-showcase-r3-7 {
            left: ($left * 7);
            top: ($top * 3);
        }
        .bb-showcase-r3-8 {
            left: ($left * 8);
            top: ($top * 3);
        }
        .bb-showcase-r3-9 {
            left: ($left * 9);
            top: ($top * 3);
        }
        .bb-showcase-r3-10 {
            left: ($left * 10);
            top: ($top * 3);
        }
        .bb-showcase-r3-11 {
            left: ($left * 11);
            top: ($top * 3);
        }
        .bb-showcase-r3-12 {
            left: ($left * 12);
            top: ($top * 3);
        }
        .bb-showcase-r3-13 {
            left: ($left * 13);
            top: ($top * 3);
        }
        .bb-showcase-r3-14 {
            left: ($left * 14);
            top: ($top * 3);
        }
        .bb-showcase-r4-0 {
            left: 0%;
            top: ($top * 4);
        }
        .bb-showcase-r4-1 {
            left: ($left * 1);
            top: ($top * 4);
        }
        .bb-showcase-r4-2 {
            left: ($left * 2);
            top: ($top * 4);
        }
        .bb-showcase-r4-3 {
            left: ($left * 3);
            top: ($top * 4);
        }
        .bb-showcase-r4-4 {
            left: ($left * 4);
            top: ($top * 4);
        }
        .bb-showcase-r4-5 {
            left: ($left * 5);
            top: ($top * 4);
        }
        .bb-showcase-r4-6 {
            left: ($left * 6);
            top: ($top * 4);
        }
        .bb-showcase-r4-7 {
            left: ($left * 7);
            top: ($top * 4);
        }
        .bb-showcase-r4-8 {
            left: ($left * 8);
            top: ($top * 4);
        }
        .bb-showcase-r4-9 {
            left: ($left * 9);
            top: ($top * 4);
        }
        .bb-showcase-r4-10 {
            left: ($left * 10);
            top: ($top * 4);
        }
        .bb-showcase-r4-11 {
            left: ($left * 11);
            top: ($top * 4);
        }
        .bb-showcase-r4-12 {
            left: ($left * 12);
            top: ($top * 4);
        }
        .bb-showcase-r4-13 {
            left: ($left * 13);
            top: ($top * 4);
        }
        .bb-showcase-r4-14 {
            left: ($left * 14);
            top: ($top * 4);
        }
        .bb-showcase-r5-0 {
            left: 0%;
            top: ($top * 5);
        }
        .bb-showcase-r5-1 {
            left: ($left * 1);
            top: ($top * 5);
        }
        .bb-showcase-r5-2 {
            left: ($left * 2);
            top: ($top * 5);
        }
        .bb-showcase-r5-3 {
            left: ($left * 3);
            top: ($top * 5);
        }
        .bb-showcase-r5-4 {
            left: ($left * 4);
            top: ($top * 5);
        }
        .bb-showcase-r5-5 {
            left: ($left * 5);
            top: ($top * 5);
        }
        .bb-showcase-r5-6 {
            left: ($left * 6);
            top: ($top * 5);
        }
        .bb-showcase-r5-7 {
            left: ($left * 7);
            top: ($top * 5);
        }
        .bb-showcase-r5-8 {
            left: ($left * 8);
            top: ($top * 5);
        }
        .bb-showcase-r5-9 {
            left: ($left * 9);
            top: ($top * 5);
        }
        .bb-showcase-r5-10 {
            left: ($left * 10);
            top: ($top * 5);
        }
        .bb-showcase-r5-11 {
            left: ($left * 11);
            top: ($top * 5);
        }
        .bb-showcase-r5-12 {
            left: ($left * 12);
            top: ($top * 5);
        }
        .bb-showcase-r5-13 {
            left: ($left * 13);
            top: ($top * 5);
        }
        .bb-showcase-r5-14 {
            left: ($left * 14);
            top: ($top * 5);
        }
        .bb-showcase-r6-0 {
            left: 0%;
            top: ($top * 6);
        }
        .bb-showcase-r6-1 {
            left: ($left * 1);
            top: ($top * 6);
        }
        .bb-showcase-r6-2 {
            left: ($left * 2);
            top: ($top * 6);
        }
        .bb-showcase-r6-3 {
            left: ($left * 3);
            top: ($top * 6);
        }
        .bb-showcase-r6-4 {
            left: ($left * 4);
            top: ($top * 6);
        }
        .bb-showcase-r6-5 {
            left: ($left * 5);
            top: ($top * 6);
        }
        .bb-showcase-r6-6 {
            left: ($left * 6);
            top: ($top * 6);
        }
        .bb-showcase-r6-7 {
            left: ($left * 7);
            top: ($top * 6);
        }
        .bb-showcase-r6-8 {
            left: ($left * 8);
            top: ($top * 6);
        }
        .bb-showcase-r6-9 {
            left: ($left * 9);
            top: ($top * 6);
        }
        .bb-showcase-r6-10 {
            left: ($left * 10);
            top: ($top * 6);
        }
        .bb-showcase-r6-11 {
            left: ($left * 11);
            top: ($top * 6);
        }
        .bb-showcase-r6-12 {
            left: ($left * 12);
            top: ($top * 6);
        }
        .bb-showcase-r6-13 {
            left: ($left * 13);
            top: ($top * 6);
        }
        .bb-showcase-r6-14 {
            left: ($left * 14);
            top: ($top * 6);
        }
        .bb-showcase-r7-0 {
            left: 0%;
            top: ($top * 7);
        }
        .bb-showcase-r7-1 {
            left: ($left * 1);
            top: ($top * 7);
        }
        .bb-showcase-r7-2 {
            left: ($left * 2);
            top: ($top * 7);
        }
        .bb-showcase-r7-3 {
            left: ($left * 3);
            top: ($top * 7);
        }
        .bb-showcase-r7-4 {
            left: ($left * 4);
            top: ($top * 7);
        }
        .bb-showcase-r7-5 {
            left: ($left * 5);
            top: ($top * 7);
        }
        .bb-showcase-r7-6 {
            left: ($left * 6);
            top: ($top * 7);
        }
        .bb-showcase-r7-7 {
            left: ($left * 7);
            top: ($top * 7);
        }
        .bb-showcase-r7-8 {
            left: ($left * 8);
            top: ($top * 7);
        }
        .bb-showcase-r7-9 {
            left: ($left * 9);
            top: ($top * 7);
        }
        .bb-showcase-r7-10 {
            left: ($left * 10);
            top: ($top * 7);
        }
        .bb-showcase-r7-11 {
            left: ($left * 11);
            top: ($top * 7);
        }
        .bb-showcase-r7-12 {
            left: ($left * 12);
            top: ($top * 7);
        }
        .bb-showcase-r7-13 {
            left: ($left * 13);
            top: ($top * 7);
        }
        .bb-showcase-r7-14 {
            left: ($left * 14);
            top: ($top * 7);
        }
        .bb-showcase-r8-0 {
            left: 0%;
            top: ($top * 8);
        }
        .bb-showcase-r8-1 {
            left: ($left * 1);
            top: ($top * 8);
        }
        .bb-showcase-r8-2 {
            left: ($left * 2);
            top: ($top * 8);
        }
        .bb-showcase-r8-3 {
            left: ($left * 3);
            top: ($top * 8);
        }
        .bb-showcase-r8-4 {
            left: ($left * 4);
            top: ($top * 8);
        }
        .bb-showcase-r8-5 {
            left: ($left * 5);
            top: ($top * 8);
        }
        .bb-showcase-r8-6 {
            left: ($left * 6);
            top: ($top * 8);
        }
        .bb-showcase-r8-7 {
            left: ($left * 7);
            top: ($top * 8);
        }
        .bb-showcase-r8-8 {
            left: ($left * 8);
            top: ($top * 8);
        }
        .bb-showcase-r8-9 {
            left: ($left * 9);
            top: ($top * 8);
        }
        .bb-showcase-r8-10 {
            left: ($left * 10);
            top: ($top * 8);
        }
        .bb-showcase-r8-11 {
            left: ($left * 11);
            top: ($top * 8);
        }
        .bb-showcase-r8-12 {
            left: ($left * 12);
            top: ($top * 8);
        }
        .bb-showcase-r8-13 {
            left: ($left * 13);
            top: ($top * 8);
        }
        .bb-showcase-r8-14 {
            left: ($left * 14);
            top: ($top * 8);
        }
        .bb-showcase-r9-0 {
            left: 0%;
            top: ($top * 9);
        }
        .bb-showcase-r9-1 {
            left: ($left * 1);
            top: ($top * 9);
        }
        .bb-showcase-r9-2 {
            left: ($left * 2);
            top: ($top * 9);
        }
        .bb-showcase-r9-3 {
            left: ($left * 3);
            top: ($top * 9);
        }
        .bb-showcase-r9-4 {
            left: ($left * 4);
            top: ($top * 9);
        }
        .bb-showcase-r9-5 {
            left: ($left * 5);
            top: ($top * 9);
        }
        .bb-showcase-r9-6 {
            left: ($left * 6);
            top: ($top * 9);
        }
        .bb-showcase-r9-7 {
            left: ($left * 7);
            top: ($top * 9);
        }
        .bb-showcase-r9-8 {
            left: ($left * 8);
            top: ($top * 9);
        }
        .bb-showcase-r9-9 {
            left: ($left * 9);
            top: ($top * 9);
        }
        .bb-showcase-r9-10 {
            left: ($left * 10);
            top: ($top * 9);
        }
        .bb-showcase-r9-11 {
            left: ($left * 11);
            top: ($top * 9);
        }
        .bb-showcase-r9-12 {
            left: ($left * 12);
            top: ($top * 9);
        }
        .bb-showcase-r9-13 {
            left: ($left * 13);
            top: ($top * 9);
        }
        .bb-showcase-r9-14 {
            left: ($left * 14);
            top: ($top * 9);
        }
        .bb-showcase-r10-0 {
            left: 0%;
            top: ($top * 10);
        }
        .bb-showcase-r10-1 {
            left: ($left * 1);
            top: ($top * 10);
        }
        .bb-showcase-r10-2 {
            left: ($left * 2);
            top: ($top * 10);
        }
        .bb-showcase-r10-3 {
            left: ($left * 3);
            top: ($top * 10);
        }
        .bb-showcase-r10-4 {
            left: ($left * 4);
            top: ($top * 10);
        }
        .bb-showcase-r10-5 {
            left: ($left * 5);
            top: ($top * 10);
        }
        .bb-showcase-r10-6 {
            left: ($left * 6);
            top: ($top * 10);
        }
        .bb-showcase-r10-7 {
            left: ($left * 7);
            top: ($top * 10);
        }
        .bb-showcase-r10-8 {
            left: ($left * 8);
            top: ($top * 10);
        }
        .bb-showcase-r10-9 {
            left: ($left * 9);
            top: ($top * 10);
        }
        .bb-showcase-r10-10 {
            left: ($left * 10);
            top: ($top * 10);
        }
        .bb-showcase-r10-11 {
            left: ($left * 11);
            top: ($top * 10);
        }
        .bb-showcase-r10-12 {
            left: ($left * 12);
            top: ($top * 10);
        }
        .bb-showcase-r10-13 {
            left: ($left * 13);
            top: ($top * 10);
        }
        .bb-showcase-r10-14 {
            left: ($left * 14);
            top: ($top * 10);
        }
        .bb-showcase-r11-0 {
            left: 0%;
            top: ($top * 11);
        }
        .bb-showcase-r11-1 {
            left: ($left * 1);
            top: ($top * 11);
        }
        .bb-showcase-r11-2 {
            left: ($left * 2);
            top: ($top * 11);
        }
        .bb-showcase-r11-3 {
            left: ($left * 3);
            top: ($top * 11);
        }
        .bb-showcase-r11-4 {
            left: ($left * 4);
            top: ($top * 11);
        }
        .bb-showcase-r11-5 {
            left: ($left * 5);
            top: ($top * 11);
        }
        .bb-showcase-r11-6 {
            left: ($left * 6);
            top: ($top * 11);
        }
        .bb-showcase-r11-7 {
            left: ($left * 7);
            top: ($top * 11);
        }
        .bb-showcase-r11-8 {
            left: ($left * 8);
            top: ($top * 11);
        }
        .bb-showcase-r11-9 {
            left: ($left * 9);
            top: ($top * 11);
        }
        .bb-showcase-r11-10 {
            left: ($left * 10);
            top: ($top * 11);
        }
        .bb-showcase-r11-11 {
            left: ($left * 11);
            top: ($top * 11);
        }
        .bb-showcase-r11-12 {
            left: ($left * 12);
            top: ($top * 11);
        }
        .bb-showcase-r11-13 {
            left: ($left * 13);
            top: ($top * 11);
        }
        .bb-showcase-r11-14 {
            left: ($left * 14);
            top: ($top * 11);
        }
        .bb-showcase-r12-0 {
            left: 0%;
            top: ($top * 12);
        }
        .bb-showcase-r12-1 {
            left: ($left * 1);
            top: ($top * 12);
        }
        .bb-showcase-r12-2 {
            left: ($left * 2);
            top: ($top * 12);
        }
        .bb-showcase-r12-3 {
            left: ($left * 3);
            top: ($top * 12);
        }
        .bb-showcase-r12-4 {
            left: ($left * 4);
            top: ($top * 12);
        }
        .bb-showcase-r12-5 {
            left: ($left * 5);
            top: ($top * 12);
        }
        .bb-showcase-r12-6 {
            left: ($left * 6);
            top: ($top * 12);
        }
        .bb-showcase-r12-7 {
            left: ($left * 7);
            top: ($top * 12);
        }
        .bb-showcase-r12-8 {
            left: ($left * 8);
            top: ($top * 12);
        }
        .bb-showcase-r12-9 {
            left: ($left * 9);
            top: ($top * 12);
        }
        .bb-showcase-r12-10 {
            left: ($left * 10);
            top: ($top * 12);
        }
        .bb-showcase-r12-11 {
            left: ($left * 11);
            top: ($top * 12);
        }
        .bb-showcase-r12-12 {
            left: ($left * 12);
            top: ($top * 12);
        }
        .bb-showcase-r12-13 {
            left: ($left * 13);
            top: ($top * 12);
        }
        .bb-showcase-r12-14 {
            left: ($left * 14);
            top: ($top * 12);
        }
        .bb-showcase-r13-0 {
            left: 0%;
            top: ($top * 13);
        }
        .bb-showcase-r13-1 {
            left: ($left * 1);
            top: ($top * 13);
        }
        .bb-showcase-r13-2 {
            left: ($left * 2);
            top: ($top * 13);
        }
        .bb-showcase-r13-3 {
            left: ($left * 3);
            top: ($top * 13);
        }
        .bb-showcase-r13-4 {
            left: ($left * 4);
            top: ($top * 13);
        }
        .bb-showcase-r13-5 {
            left: ($left * 5);
            top: ($top * 13);
        }
        .bb-showcase-r13-6 {
            left: ($left * 6);
            top: ($top * 13);
        }
        .bb-showcase-r13-7 {
            left: ($left * 7);
            top: ($top * 13);
        }
        .bb-showcase-r13-8 {
            left: ($left * 8);
            top: ($top * 13);
        }
        .bb-showcase-r13-9 {
            left: ($left * 9);
            top: ($top * 13);
        }
        .bb-showcase-r13-10 {
            left: ($left * 10);
            top: ($top * 13);
        }
        .bb-showcase-r13-11 {
            left: ($left * 11);
            top: ($top * 13);
        }
        .bb-showcase-r13-12 {
            left: ($left * 12);
            top: ($top * 13);
        }
        .bb-showcase-r13-13 {
            left: ($left * 13);
            top: ($top * 13);
        }
        .bb-showcase-r13-14 {
            left: ($left * 14);
            top: ($top * 13);
        }
        .bb-showcase-r14-0 {
            left: 0%;
            top: ($top * 14);
        }
        .bb-showcase-r14-1 {
            left: ($left * 1);
            top: ($top * 14);
        }
        .bb-showcase-r14-2 {
            left: ($left * 2);
            top: ($top * 14);
        }
        .bb-showcase-r14-3 {
            left: ($left * 3);
            top: ($top * 14);
        }
        .bb-showcase-r14-4 {
            left: ($left * 4);
            top: ($top * 14);
        }
        .bb-showcase-r14-5 {
            left: ($left * 5);
            top: ($top * 14);
        }
        .bb-showcase-r14-6 {
            left: ($left * 6);
            top: ($top * 14);
        }
        .bb-showcase-r14-7 {
            left: ($left * 7);
            top: ($top * 14);
        }
        .bb-showcase-r14-8 {
            left: ($left * 8);
            top: ($top * 14);
        }
        .bb-showcase-r14-9 {
            left: ($left * 9);
            top: ($top * 14);
        }
        .bb-showcase-r14-10 {
            left: ($left * 10);
            top: ($top * 14);
        }
        .bb-showcase-r14-11 {
            left: ($left * 11);
            top: ($top * 14);
        }
        .bb-showcase-r14-12 {
            left: ($left * 12);
            top: ($top * 14);
        }
        .bb-showcase-r14-13 {
            left: ($left * 13);
            top: ($top * 14);
        }
        .bb-showcase-r14-14 {
            left: ($left * 14);
            top: ($top * 14);
        }
    } */

    /*  @media (min-width: 1400px) {
        $left: 17%;
        $top: 17%;

        [class*="showcase-r"] {
            width: 16%;
            position: absolute;
            color: #ffffff;
        }
        .bb-showcase-r0-0 {
            left: 0%;
            top: 0%;
        }
        .bb-showcase-r0-1 {
            left: ($left * 1);
            top: 0%;
        }
        .bb-showcase-r0-2 {
            left: ($left * 2);
            top: 0%;
        }
        .bb-showcase-r0-3 {
            left: ($left * 3);
            top: 0%;
        }
        .bb-showcase-r0-4 {
            left: ($left * 4);
            top: 0%;
        }
        .bb-showcase-r0-5 {
            left: ($left * 5);
            top: 0%;
        }
        .bb-showcase-r0-6 {
            left: ($left * 6);
            top: 0%;
        }
        .bb-showcase-r0-7 {
            left: ($left * 7);
            top: 0%;
        }
        .bb-showcase-r0-8 {
            left: ($left * 8);
            top: 0%;
        }
        .bb-showcase-r0-9 {
            left: ($left * 9);
            top: 0%;
        }
        .bb-showcase-r0-10 {
            left: ($left * 10);
            top: 0%;
        }
        .bb-showcase-r0-11 {
            left: ($left * 11);
            top: 0%;
        }
        .bb-showcase-r0-11 {
            left: ($left * 12);
            top: 0%;
        }
        .bb-showcase-r0-11 {
            left: ($left * 13);
            top: 0%;
        }
        .bb-showcase-r0-11 {
            left: ($left * 14);
            top: 0%;
        }
        .bb-showcase-r1-0 {
            left: 0%;
            top: ($top * 1);
        }
        .bb-showcase-r1-1 {
            left: ($left * 1);
            top: ($top * 1);
        }
        .bb-showcase-r1-2 {
            left: ($left * 2);
            top: ($top * 1);
        }
        .bb-showcase-r1-3 {
            left: ($left * 3);
            top: ($top * 1);
        }
        .bb-showcase-r1-4 {
            left: ($left * 4);
            top: ($top * 1);
        }
        .bb-showcase-r1-5 {
            left: ($left * 5);
            top: ($top * 1);
        }
        .bb-showcase-r1-6 {
            left: ($left * 6);
            top: ($top * 1);
        }
        .bb-showcase-r1-7 {
            left: ($left * 7);
            top: ($top * 1);
        }
        .bb-showcase-r1-8 {
            left: ($left * 8);
            top: ($top * 1);
        }
        .bb-showcase-r1-9 {
            left: ($left * 9);
            top: ($top * 1);
        }
        .bb-showcase-r1-10 {
            left: ($left * 10);
            top: ($top * 1);
        }
        .bb-showcase-r1-11 {
            left: ($left * 11);
            top: ($top * 1);
        }
        .bb-showcase-r1-12 {
            left: ($left * 12);
            top: ($top * 1);
        }
        .bb-showcase-r1-13 {
            left: ($left * 13);
            top: ($top * 1);
        }
        .bb-showcase-r1-14 {
            left: ($left * 14);
            top: ($top * 1);
        }
        .bb-showcase-r2-0 {
            left: 0%;
            top: ($top * 2);
        }
        .bb-showcase-r2-1 {
            left: ($left * 1);
            top: ($top * 2);
        }
        .bb-showcase-r2-2 {
            left: ($left * 2);
            top: ($top * 2);
        }
        .bb-showcase-r2-3 {
            left: ($left * 3);
            top: ($top * 2);
        }
        .bb-showcase-r2-4 {
            left: ($left * 4);
            top: ($top * 2);
        }
        .bb-showcase-r2-5 {
            left: ($left * 5);
            top: ($top * 2);
        }
        .bb-showcase-r2-6 {
            left: ($left * 6);
            top: ($top * 2);
        }
        .bb-showcase-r2-7 {
            left: ($left * 7);
            top: ($top * 2);
        }
        .bb-showcase-r2-8 {
            left: ($left * 8);
            top: ($top * 2);
        }
        .bb-showcase-r2-9 {
            left: ($left * 9);
            top: ($top * 2);
        }
        .bb-showcase-r2-10 {
            left: ($left * 10);
            top: ($top * 2);
        }
        .bb-showcase-r2-11 {
            left: ($left * 11);
            top: ($top * 2);
        }
        .bb-showcase-r2-12 {
            left: ($left * 12);
            top: ($top * 2);
        }
        .bb-showcase-r2-13 {
            left: ($left * 13);
            top: ($top * 2);
        }
        .bb-showcase-r2-14 {
            left: ($left * 14);
            top: ($top * 2);
        }
        .bb-showcase-r3-0 {
            left: 0%;
            top: ($top * 3);
        }
        .bb-showcase-r3-1 {
            left: ($left * 1);
            top: ($top * 3);
        }
        .bb-showcase-r3-2 {
            left: ($left * 2);
            top: ($top * 3);
        }
        .bb-showcase-r3-3 {
            left: ($left * 3);
            top: ($top * 3);
        }
        .bb-showcase-r3-4 {
            left: ($left * 4);
            top: ($top * 3);
        }
        .bb-showcase-r3-5 {
            left: ($left * 5);
            top: ($top * 3);
        }
        .bb-showcase-r3-6 {
            left: ($left * 6);
            top: ($top * 3);
        }
        .bb-showcase-r3-7 {
            left: ($left * 7);
            top: ($top * 3);
        }
        .bb-showcase-r3-8 {
            left: ($left * 8);
            top: ($top * 3);
        }
        .bb-showcase-r3-9 {
            left: ($left * 9);
            top: ($top * 3);
        }
        .bb-showcase-r3-10 {
            left: ($left * 10);
            top: ($top * 3);
        }
        .bb-showcase-r3-11 {
            left: ($left * 11);
            top: ($top * 3);
        }
        .bb-showcase-r3-12 {
            left: ($left * 12);
            top: ($top * 3);
        }
        .bb-showcase-r3-13 {
            left: ($left * 13);
            top: ($top * 3);
        }
        .bb-showcase-r3-14 {
            left: ($left * 14);
            top: ($top * 3);
        }
        .bb-showcase-r4-0 {
            left: 0%;
            top: ($top * 4);
        }
        .bb-showcase-r4-1 {
            left: ($left * 1);
            top: ($top * 4);
        }
        .bb-showcase-r4-2 {
            left: ($left * 2);
            top: ($top * 4);
        }
        .bb-showcase-r4-3 {
            left: ($left * 3);
            top: ($top * 4);
        }
        .bb-showcase-r4-4 {
            left: ($left * 4);
            top: ($top * 4);
        }
        .bb-showcase-r4-5 {
            left: ($left * 5);
            top: ($top * 4);
        }
        .bb-showcase-r4-6 {
            left: ($left * 6);
            top: ($top * 4);
        }
        .bb-showcase-r4-7 {
            left: ($left * 7);
            top: ($top * 4);
        }
        .bb-showcase-r4-8 {
            left: ($left * 8);
            top: ($top * 4);
        }
        .bb-showcase-r4-9 {
            left: ($left * 9);
            top: ($top * 4);
        }
        .bb-showcase-r4-10 {
            left: ($left * 10);
            top: ($top * 4);
        }
        .bb-showcase-r4-11 {
            left: ($left * 11);
            top: ($top * 4);
        }
        .bb-showcase-r4-12 {
            left: ($left * 12);
            top: ($top * 4);
        }
        .bb-showcase-r4-13 {
            left: ($left * 13);
            top: ($top * 4);
        }
        .bb-showcase-r4-14 {
            left: ($left * 14);
            top: ($top * 4);
        }
        .bb-showcase-r5-0 {
            left: 0%;
            top: ($top * 5);
        }
        .bb-showcase-r5-1 {
            left: ($left * 1);
            top: ($top * 5);
        }
        .bb-showcase-r5-2 {
            left: ($left * 2);
            top: ($top * 5);
        }
        .bb-showcase-r5-3 {
            left: ($left * 3);
            top: ($top * 5);
        }
        .bb-showcase-r5-4 {
            left: ($left * 4);
            top: ($top * 5);
        }
        .bb-showcase-r5-5 {
            left: ($left * 5);
            top: ($top * 5);
        }
        .bb-showcase-r5-6 {
            left: ($left * 6);
            top: ($top * 5);
        }
        .bb-showcase-r5-7 {
            left: ($left * 7);
            top: ($top * 5);
        }
        .bb-showcase-r5-8 {
            left: ($left * 8);
            top: ($top * 5);
        }
        .bb-showcase-r5-9 {
            left: ($left * 9);
            top: ($top * 5);
        }
        .bb-showcase-r5-10 {
            left: ($left * 10);
            top: ($top * 5);
        }
        .bb-showcase-r5-11 {
            left: ($left * 11);
            top: ($top * 5);
        }
        .bb-showcase-r5-12 {
            left: ($left * 12);
            top: ($top * 5);
        }
        .bb-showcase-r5-13 {
            left: ($left * 13);
            top: ($top * 5);
        }
        .bb-showcase-r5-14 {
            left: ($left * 14);
            top: ($top * 5);
        }
        .bb-showcase-r6-0 {
            left: 0%;
            top: ($top * 6);
        }
        .bb-showcase-r6-1 {
            left: ($left * 1);
            top: ($top * 6);
        }
        .bb-showcase-r6-2 {
            left: ($left * 2);
            top: ($top * 6);
        }
        .bb-showcase-r6-3 {
            left: ($left * 3);
            top: ($top * 6);
        }
        .bb-showcase-r6-4 {
            left: ($left * 4);
            top: ($top * 6);
        }
        .bb-showcase-r6-5 {
            left: ($left * 5);
            top: ($top * 6);
        }
        .bb-showcase-r6-6 {
            left: ($left * 6);
            top: ($top * 6);
        }
        .bb-showcase-r6-7 {
            left: ($left * 7);
            top: ($top * 6);
        }
        .bb-showcase-r6-8 {
            left: ($left * 8);
            top: ($top * 6);
        }
        .bb-showcase-r6-9 {
            left: ($left * 9);
            top: ($top * 6);
        }
        .bb-showcase-r6-10 {
            left: ($left * 10);
            top: ($top * 6);
        }
        .bb-showcase-r6-11 {
            left: ($left * 11);
            top: ($top * 6);
        }
        .bb-showcase-r6-12 {
            left: ($left * 12);
            top: ($top * 6);
        }
        .bb-showcase-r6-13 {
            left: ($left * 13);
            top: ($top * 6);
        }
        .bb-showcase-r6-14 {
            left: ($left * 14);
            top: ($top * 6);
        }
        .bb-showcase-r7-0 {
            left: 0%;
            top: ($top * 7);
        }
        .bb-showcase-r7-1 {
            left: ($left * 1);
            top: ($top * 7);
        }
        .bb-showcase-r7-2 {
            left: ($left * 2);
            top: ($top * 7);
        }
        .bb-showcase-r7-3 {
            left: ($left * 3);
            top: ($top * 7);
        }
        .bb-showcase-r7-4 {
            left: ($left * 4);
            top: ($top * 7);
        }
        .bb-showcase-r7-5 {
            left: ($left * 5);
            top: ($top * 7);
        }
        .bb-showcase-r7-6 {
            left: ($left * 6);
            top: ($top * 7);
        }
        .bb-showcase-r7-7 {
            left: ($left * 7);
            top: ($top * 7);
        }
        .bb-showcase-r7-8 {
            left: ($left * 8);
            top: ($top * 7);
        }
        .bb-showcase-r7-9 {
            left: ($left * 9);
            top: ($top * 7);
        }
        .bb-showcase-r7-10 {
            left: ($left * 10);
            top: ($top * 7);
        }
        .bb-showcase-r7-11 {
            left: ($left * 11);
            top: ($top * 7);
        }
        .bb-showcase-r7-12 {
            left: ($left * 12);
            top: ($top * 7);
        }
        .bb-showcase-r7-13 {
            left: ($left * 13);
            top: ($top * 7);
        }
        .bb-showcase-r7-14 {
            left: ($left * 14);
            top: ($top * 7);
        }
        .bb-showcase-r8-0 {
            left: 0%;
            top: ($top * 8);
        }
        .bb-showcase-r8-1 {
            left: ($left * 1);
            top: ($top * 8);
        }
        .bb-showcase-r8-2 {
            left: ($left * 2);
            top: ($top * 8);
        }
        .bb-showcase-r8-3 {
            left: ($left * 3);
            top: ($top * 8);
        }
        .bb-showcase-r8-4 {
            left: ($left * 4);
            top: ($top * 8);
        }
        .bb-showcase-r8-5 {
            left: ($left * 5);
            top: ($top * 8);
        }
        .bb-showcase-r8-6 {
            left: ($left * 6);
            top: ($top * 8);
        }
        .bb-showcase-r8-7 {
            left: ($left * 7);
            top: ($top * 8);
        }
        .bb-showcase-r8-8 {
            left: ($left * 8);
            top: ($top * 8);
        }
        .bb-showcase-r8-9 {
            left: ($left * 9);
            top: ($top * 8);
        }
        .bb-showcase-r8-10 {
            left: ($left * 10);
            top: ($top * 8);
        }
        .bb-showcase-r8-11 {
            left: ($left * 11);
            top: ($top * 8);
        }
        .bb-showcase-r8-12 {
            left: ($left * 12);
            top: ($top * 8);
        }
        .bb-showcase-r8-13 {
            left: ($left * 13);
            top: ($top * 8);
        }
        .bb-showcase-r8-14 {
            left: ($left * 14);
            top: ($top * 8);
        }
        .bb-showcase-r9-0 {
            left: 0%;
            top: ($top * 9);
        }
        .bb-showcase-r9-1 {
            left: ($left * 1);
            top: ($top * 9);
        }
        .bb-showcase-r9-2 {
            left: ($left * 2);
            top: ($top * 9);
        }
        .bb-showcase-r9-3 {
            left: ($left * 3);
            top: ($top * 9);
        }
        .bb-showcase-r9-4 {
            left: ($left * 4);
            top: ($top * 9);
        }
        .bb-showcase-r9-5 {
            left: ($left * 5);
            top: ($top * 9);
        }
        .bb-showcase-r9-6 {
            left: ($left * 6);
            top: ($top * 9);
        }
        .bb-showcase-r9-7 {
            left: ($left * 7);
            top: ($top * 9);
        }
        .bb-showcase-r9-8 {
            left: ($left * 8);
            top: ($top * 9);
        }
        .bb-showcase-r9-9 {
            left: ($left * 9);
            top: ($top * 9);
        }
        .bb-showcase-r9-10 {
            left: ($left * 10);
            top: ($top * 9);
        }
        .bb-showcase-r9-11 {
            left: ($left * 11);
            top: ($top * 9);
        }
        .bb-showcase-r9-12 {
            left: ($left * 12);
            top: ($top * 9);
        }
        .bb-showcase-r9-13 {
            left: ($left * 13);
            top: ($top * 9);
        }
        .bb-showcase-r9-14 {
            left: ($left * 14);
            top: ($top * 9);
        }
        .bb-showcase-r10-0 {
            left: 0%;
            top: ($top * 10);
        }
        .bb-showcase-r10-1 {
            left: ($left * 1);
            top: ($top * 10);
        }
        .bb-showcase-r10-2 {
            left: ($left * 2);
            top: ($top * 10);
        }
        .bb-showcase-r10-3 {
            left: ($left * 3);
            top: ($top * 10);
        }
        .bb-showcase-r10-4 {
            left: ($left * 4);
            top: ($top * 10);
        }
        .bb-showcase-r10-5 {
            left: ($left * 5);
            top: ($top * 10);
        }
        .bb-showcase-r10-6 {
            left: ($left * 6);
            top: ($top * 10);
        }
        .bb-showcase-r10-7 {
            left: ($left * 7);
            top: ($top * 10);
        }
        .bb-showcase-r10-8 {
            left: ($left * 8);
            top: ($top * 10);
        }
        .bb-showcase-r10-9 {
            left: ($left * 9);
            top: ($top * 10);
        }
        .bb-showcase-r10-10 {
            left: ($left * 10);
            top: ($top * 10);
        }
        .bb-showcase-r10-11 {
            left: ($left * 11);
            top: ($top * 10);
        }
        .bb-showcase-r10-12 {
            left: ($left * 12);
            top: ($top * 10);
        }
        .bb-showcase-r10-13 {
            left: ($left * 13);
            top: ($top * 10);
        }
        .bb-showcase-r10-14 {
            left: ($left * 14);
            top: ($top * 10);
        }
        .bb-showcase-r11-0 {
            left: 0%;
            top: ($top * 11);
        }
        .bb-showcase-r11-1 {
            left: ($left * 1);
            top: ($top * 11);
        }
        .bb-showcase-r11-2 {
            left: ($left * 2);
            top: ($top * 11);
        }
        .bb-showcase-r11-3 {
            left: ($left * 3);
            top: ($top * 11);
        }
        .bb-showcase-r11-4 {
            left: ($left * 4);
            top: ($top * 11);
        }
        .bb-showcase-r11-5 {
            left: ($left * 5);
            top: ($top * 11);
        }
        .bb-showcase-r11-6 {
            left: ($left * 6);
            top: ($top * 11);
        }
        .bb-showcase-r11-7 {
            left: ($left * 7);
            top: ($top * 11);
        }
        .bb-showcase-r11-8 {
            left: ($left * 8);
            top: ($top * 11);
        }
        .bb-showcase-r11-9 {
            left: ($left * 9);
            top: ($top * 11);
        }
        .bb-showcase-r11-10 {
            left: ($left * 10);
            top: ($top * 11);
        }
        .bb-showcase-r11-11 {
            left: ($left * 11);
            top: ($top * 11);
        }
        .bb-showcase-r11-12 {
            left: ($left * 12);
            top: ($top * 11);
        }
        .bb-showcase-r11-13 {
            left: ($left * 13);
            top: ($top * 11);
        }
        .bb-showcase-r11-14 {
            left: ($left * 14);
            top: ($top * 11);
        }
        .bb-showcase-r12-0 {
            left: 0%;
            top: ($top * 12);
        }
        .bb-showcase-r12-1 {
            left: ($left * 1);
            top: ($top * 12);
        }
        .bb-showcase-r12-2 {
            left: ($left * 2);
            top: ($top * 12);
        }
        .bb-showcase-r12-3 {
            left: ($left * 3);
            top: ($top * 12);
        }
        .bb-showcase-r12-4 {
            left: ($left * 4);
            top: ($top * 12);
        }
        .bb-showcase-r12-5 {
            left: ($left * 5);
            top: ($top * 12);
        }
        .bb-showcase-r12-6 {
            left: ($left * 6);
            top: ($top * 12);
        }
        .bb-showcase-r12-7 {
            left: ($left * 7);
            top: ($top * 12);
        }
        .bb-showcase-r12-8 {
            left: ($left * 8);
            top: ($top * 12);
        }
        .bb-showcase-r12-9 {
            left: ($left * 9);
            top: ($top * 12);
        }
        .bb-showcase-r12-10 {
            left: ($left * 10);
            top: ($top * 12);
        }
        .bb-showcase-r12-11 {
            left: ($left * 11);
            top: ($top * 12);
        }
        .bb-showcase-r12-12 {
            left: ($left * 12);
            top: ($top * 12);
        }
        .bb-showcase-r12-13 {
            left: ($left * 13);
            top: ($top * 12);
        }
        .bb-showcase-r12-14 {
            left: ($left * 14);
            top: ($top * 12);
        }
        .bb-showcase-r13-0 {
            left: 0%;
            top: ($top * 13);
        }
        .bb-showcase-r13-1 {
            left: ($left * 1);
            top: ($top * 13);
        }
        .bb-showcase-r13-2 {
            left: ($left * 2);
            top: ($top * 13);
        }
        .bb-showcase-r13-3 {
            left: ($left * 3);
            top: ($top * 13);
        }
        .bb-showcase-r13-4 {
            left: ($left * 4);
            top: ($top * 13);
        }
        .bb-showcase-r13-5 {
            left: ($left * 5);
            top: ($top * 13);
        }
        .bb-showcase-r13-6 {
            left: ($left * 6);
            top: ($top * 13);
        }
        .bb-showcase-r13-7 {
            left: ($left * 7);
            top: ($top * 13);
        }
        .bb-showcase-r13-8 {
            left: ($left * 8);
            top: ($top * 13);
        }
        .bb-showcase-r13-9 {
            left: ($left * 9);
            top: ($top * 13);
        }
        .bb-showcase-r13-10 {
            left: ($left * 10);
            top: ($top * 13);
        }
        .bb-showcase-r13-11 {
            left: ($left * 11);
            top: ($top * 13);
        }
        .bb-showcase-r13-12 {
            left: ($left * 12);
            top: ($top * 13);
        }
        .bb-showcase-r13-13 {
            left: ($left * 13);
            top: ($top * 13);
        }
        .bb-showcase-r13-14 {
            left: ($left * 14);
            top: ($top * 13);
        }
        .bb-showcase-r14-0 {
            left: 0%;
            top: ($top * 14);
        }
        .bb-showcase-r14-1 {
            left: ($left * 1);
            top: ($top * 14);
        }
        .bb-showcase-r14-2 {
            left: ($left * 2);
            top: ($top * 14);
        }
        .bb-showcase-r14-3 {
            left: ($left * 3);
            top: ($top * 14);
        }
        .bb-showcase-r14-4 {
            left: ($left * 4);
            top: ($top * 14);
        }
        .bb-showcase-r14-5 {
            left: ($left * 5);
            top: ($top * 14);
        }
        .bb-showcase-r14-6 {
            left: ($left * 6);
            top: ($top * 14);
        }
        .bb-showcase-r14-7 {
            left: ($left * 7);
            top: ($top * 14);
        }
        .bb-showcase-r14-8 {
            left: ($left * 8);
            top: ($top * 14);
        }
        .bb-showcase-r14-9 {
            left: ($left * 9);
            top: ($top * 14);
        }
        .bb-showcase-r14-10 {
            left: ($left * 10);
            top: ($top * 14);
        }
        .bb-showcase-r14-11 {
            left: ($left * 11);
            top: ($top * 14);
        }
        .bb-showcase-r14-12 {
            left: ($left * 12);
            top: ($top * 14);
        }
        .bb-showcase-r14-13 {
            left: ($left * 13);
            top: ($top * 14);
        }
        .bb-showcase-r14-14 {
            left: ($left * 14);
            top: ($top * 14);
        }
    } */
}
